import React, { useState } from "react";
import { Button, message } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { useMutation, gql } from "@apollo/client";
import Dragger from 'antd/es/upload/Dragger';

const UPLOAD_FILES_MUTATION = gql`
  mutation createUnprocessedDocument($files: [Upload!]!) {
    createUnprocessedDocument(files: $files) {
      filename
      success
      error
    }
  }
`;

interface UploadResult {
  filename: string;
  success: boolean;
  error: string | null;
}

const Uploads: React.FC = () => {
  const [fileList, setFileList] = useState<File[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [responseData, setResponseData] = useState<UploadResult[] | null>(null);

  const [uploadFiles] = useMutation(UPLOAD_FILES_MUTATION, {
    onCompleted: (data) => {
      setResponseData(data.createUnprocessedDocument);
      message.success(`Files uploaded successfully.`);
      setFileList([]);
    },
    onError: (error) => {
      console.error("Upload error:", error);
      message.error(`File upload failed.`);
    },
  });

  const handleUpload = () => {
    if (fileList.length > 0) {
      const _fileList = fileList.map((file) => new File([file], file.name));
      uploadFiles({ variables: { files: _fileList } });
    } else {
      message.warning("No files selected for upload.");
    }
  };

  const handleChange = ({ fileList: newFileList }: any) => {
    // Keep only the original file objects without extra metadata
    const files = newFileList.map((fileObj: any) => fileObj.originFileObj);
    setFileList(files);
  };

  return (
    <div
      className="scheduled-payments-container"
      style={{ padding: 20, marginTop: 20 }}
    >
      <Dragger
        accept="application/pdf"
        listType="picture-card"
        multiple={true}
        beforeUpload={() => false} // Prevent auto upload
        onChange={handleChange}
        fileList={fileList.map((file, index) => {
          return {
            uid: index.toString(),
            name: file.name,
            status: "done",
          };
        })}
        showUploadList={true}
      >
        <div>
          <InboxOutlined />
          <div style={{ marginTop: 8 }}>Upload PDFs</div>
        </div>
      </Dragger>
      <Button type="primary" onClick={handleUpload} style={{ marginTop: 20, marginBottom: 20 }}>
        Upload Selected Files
      </Button>
    </div>
  );
};

export default Uploads;
