import { ApolloClient, InMemoryCache, ApolloLink, concat } from '@apollo/client';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';

const getGraphQLUri = () => {
  const hostname = window.location.hostname;
  if (hostname.includes('develop')) {
    return 'https://sbs-graphql-dev.silverbills.co/graphql';
  } else if (hostname.includes('stage')) {
    return 'https://sbs-graphql-stage.silverbills.co/graphql';
  } else if (hostname.includes('master')) {
    return 'https://sbs-graphql-master.silverbills.co/graphql';
  } else if (hostname.includes('localhost')) {
    return 'http://localhost:3000/graphql';
  } else {
    throw new Error(`Unrecognized hostname: ${hostname}`);
  }
};

const uploadLink = createUploadLink({
  uri: getGraphQLUri(),
  headers: {
    'x-apollo-operation-name': 'uploads', // Add your operation name or another unique identifier
  },
});

const authMiddleware = new ApolloLink((operation, forward) => {
  // Add the authorization to the headers
  const accessToken = localStorage.getItem('accessToken');
  operation.setContext({
    headers: {
      authorization: accessToken ? `Bearer ${accessToken}` : '',
    }
  });
  return forward(operation);
});

const client = new ApolloClient({
  link: concat(authMiddleware, uploadLink),
  // link: concat(authMiddleware, httpLink),
  cache: new InMemoryCache(),
});

export default client;
